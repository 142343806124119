.ml5 {
  /* position: relative; */
  /* font-weight: 300; */
  /* font-size: 4.5em; */
  /* color: whitesmoke; */
}

.ml5 .text-wrapper {
  position: relative;
  display: inline-block;
  /* padding-top: 0.1em;
  padding-right: 0.05em;
  padding-bottom: 0.15em; */
  /* line-height: 1em; */
}

.ml5 .line {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 2.15px;
  width: 100%;
  background-color: whitesmoke;
  box-shadow: 1px 1px 22.5px #0abab5;
  transform-origin: 0.5 0;
}

.ml5 .ampersand {
  /* font-family: Baskerville, "EB Garamond", serif;
  font-style: italic;
  font-weight: 400;
  width: 1em;
  margin-right: -0.1em;
  margin-left: -0.1em;  */
}

.ml5 .letters,.ampersand {
  display: inline-block;
  opacity: 0;
}