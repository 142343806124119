

.bg-primary {
	background-color: #0abab5 ;
	/* color: #f5f5f5 ; */
}

.button-bg-primary {
	border: 2px solid #0abab5 ;
	background-color: #0abab5 ;
	color: #f5f5f5 ;
}

.signInButton:focus {
	border: 5px solid red;
}



.button-primary-outline {
	border: 2px solid #0abab5 !important;
	background-color: transparent ;
	color: #0abab5;
} 

.button-form-nav {
	/* border: 2px solid #0abab5 !important; */
	background-color: #0abab5 ;
	color: #f5f5f5;
	font-style: italic !important;
	/* text-shadow: 0 0 2px lightgrey !important; */
}

.button-form-nav-dead {
	/* border: 2px solid #0abab5 !important; */
	background-color: lightgrey ;
	color: #f5f5f5;
	font-style: italic !important;
	/* text-shadow: 0 0 2px lightgrey !important; */
}

.button-form-modal {
	background: transparent;
	font-style: italic !important;
}

.button-grey-outline {
	/* border: 2px solid #626262;
	background: transparent;
	color: #626262; */
}

.bg-primary-light {
	background-color: #0ce9e2 ;
	border: 1px solid whitesmoke;
	color: whitesmoke;
}

.bg-primary-dark {
	background-color: #089591 ;
}

.bg-primary-xlight {
	background-color: #c2e9e6;
	border: 1px solid whitesmoke;
	color: grey;
}

.bg-primary-xdark {
	background-color: #193c3a;
}

.bg-primary-desat {
	background-color: #1ca8a4;
}

.bg-primary-xsat {
	background-color: #089591;
}

.bg-primary-invert {
	border: 2px solid #f5454a ;
	background-color: #f5454a;
	color: #f5f5f5;
}

.bg-primary-invert-outline {
	border: 2px solid #f5454a ;
	background: transparent ;
	color: #f5454a ;
	/* border-radius: "5px"; */
}

.bg-primary-compl {
	background-color: #aa1a30;
}

.bg-primary-gray {
	background-color: #626262;
}

.bg-whitesmoke {
	background-color: whitesmoke;
}

.brdr-rad {
	/* border-radius: 1.4%; */
	border-top-left-radius: 1.25%;
	border-top-right-radius: 1.25%;
	/* box-shadow: 5px black; */
	box-shadow: 0 0 1px grey !important;
}


.bg-red {
	background-color: red;
}

.bg-green {
	background-color: green;
}

.bg-blue {
	background-color: blue;
}

.bg-orange {
	background-color: orange;
}

.bg-yellow {
	background-color: yellow;
}

.bg-brown {
	background-color: brown;
}

.bg-grey, .bg-gray {
	background-color: grey;
}

.bg-lightgrey, .bg-lightgray {
	background-color: lightgrey;
}

.bg-teal {
	background-color: teal;
}

.bg-black {
	background-color: black;
}

.bg-purple {
	background-color: purple;
}

.bg-indigo {
	background-color: indigo;
}




.bg-secondary {
	background-color: #0abab5;
}

.bg-secondary-light {
	background-color: #0ce9e2;
}

.bg-secondary-dark {
	background-color: #089591;
}

.bg-secondary-xlight {
	background-color: #c2e9e6;
}

.bg-secondary-xdark {
	background-color: #193c3a;
}

.bg-secondary-desat {
	background-color: #1ca8a4;
}

.bg-secondary-xsat {
	background-color: #089591;
}

.bg-secondary-invert {
	background-color: #f5454a;
}

.bg-secondary-compl {
	background-color: #aa1a30;
}

.bg-secondary-grey,.bg-secondary-gray {
	background-color: #626262;
}