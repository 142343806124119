.loader {
  margin: 75px auto !important;
  font-size: 100% !important;
  width: 1em !important;
  height: 1em !important;
  border-radius: 50% !important;
  position: relative !important;
  text-indent: -9999em !important;
  -webkit-animation: load5 .75s infinite ease !important;
  animation: load5 .75s infinite ease !important;
  -webkit-transform: translateZ(0) !important;
  -ms-transform: translateZ(0) !important;
  transform: translateZ(0) !important;

}

@-webkit-keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}
@keyframes load5 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff, 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.5), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7), 1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff, 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5), 2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff, 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.5), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff, -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2), -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5), 0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff, -2.6em 0em 0 0em rgba(255, 255, 255, 0.2), -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.5), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff, -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2), 1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2), 2.5em 0em 0 0em rgba(255, 255, 255, 0.2), 1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2), 0em 2.5em 0 0em rgba(255, 255, 255, 0.2), -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5), -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}



.fsLoader {
  margin: 75px auto !important;
  font-size: 100% !important;
  width: 1em !important;
  height: 1em !important;
  border-radius: 50% !important;
  position: relative !important;
  text-indent: -9999em !important;
  -webkit-animation: load6 .75s infinite ease !important;
  animation: load6 .75s infinite ease !important;
  -webkit-transform: translateZ(0) !important;
  -ms-transform: translateZ(0) !important;
  transform: translateZ(0) !important;
}

/* @-webkit-keyframes load6 { */
@keyframes load6 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #f5f5f5, 1.8em -1.8em 0 0em rgba(128,128,128, 0.4), 2.5em 0em 0 0em rgba(128,128,128, 0.4), 1.75em 1.75em 0 0em rgba(128,128,128, 0.4), 0em 2.5em 0 0em rgba(128,128,128, 0.4), -1.8em 1.8em 0 0em rgba(128,128,128, 0.4), -2.6em 0em 0 0em rgba(128,128,128, 0.7), -1.8em -1.8em 0 0em rgba(128,128,128, 0.9);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(128,128,128, 0.9), 1.8em -1.8em 0 0em #f5f5f5, 2.5em 0em 0 0em rgba(128,128,128, 0.4), 1.75em 1.75em 0 0em rgba(128,128,128, 0.4), 0em 2.5em 0 0em rgba(128,128,128, 0.4), -1.8em 1.8em 0 0em rgba(128,128,128, 0.4), -2.6em 0em 0 0em rgba(128,128,128, 0.4), -1.8em -1.8em 0 0em rgba(128,128,128, 0.7);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(128,128,128, 0.7), 1.8em -1.8em 0 0em rgba(128,128,128, 0.9), 2.5em 0em 0 0em #f5f5f5, 1.75em 1.75em 0 0em rgba(128,128,128, 0.4), 0em 2.5em 0 0em rgba(128,128,128, 0.4), -1.8em 1.8em 0 0em rgba(128,128,128, 0.4), -2.6em 0em 0 0em rgba(128,128,128, 0.4), -1.8em -1.8em 0 0em rgba(128,128,128, 0.4);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(128,128,128, 0.4), 1.8em -1.8em 0 0em rgba(128,128,128, 0.7), 2.5em 0em 0 0em rgba(128,128,128, 0.9), 1.75em 1.75em 0 0em #f5f5f5, 0em 2.5em 0 0em rgba(128,128,128, 0.4), -1.8em 1.8em 0 0em rgba(128,128,128, 0.4), -2.6em 0em 0 0em rgba(128,128,128, 0.4), -1.8em -1.8em 0 0em rgba(128,128,128, 0.4);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(128,128,128, 0.4), 1.8em -1.8em 0 0em rgba(128,128,128, 0.4), 2.5em 0em 0 0em rgba(128,128,128, 0.7), 1.75em 1.75em 0 0em rgba(128,128,128, 0.9), 0em 2.5em 0 0em #f5f5f5, -1.8em 1.8em 0 0em rgba(128,128,128, 0.4), -2.6em 0em 0 0em rgba(128,128,128, 0.4), -1.8em -1.8em 0 0em rgba(128,128,128, 0.4);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(128,128,128, 0.4), 1.8em -1.8em 0 0em rgba(128,128,128, 0.4), 2.5em 0em 0 0em rgba(128,128,128, 0.4), 1.75em 1.75em 0 0em rgba(128,128,128, 0.7), 0em 2.5em 0 0em rgba(128,128,128, 0.9), -1.8em 1.8em 0 0em #f5f5f5, -2.6em 0em 0 0em rgba(128,128,128, 0.4), -1.8em -1.8em 0 0em rgba(128,128,128, 0.4);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(128,128,128, 0.4), 1.8em -1.8em 0 0em rgba(128,128,128, 0.4), 2.5em 0em 0 0em rgba(128,128,128, 0.4), 1.75em 1.75em 0 0em rgba(128,128,128, 0.4), 0em 2.5em 0 0em rgba(128,128,128, 0.7), -1.8em 1.8em 0 0em rgba(128,128,128, 0.9), -2.6em 0em 0 0em #f5f5f5, -1.8em -1.8em 0 0em rgba(128,128,128, 0.4);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(128,128,128, 0.4), 1.8em -1.8em 0 0em rgba(128,128,128, 0.4), 2.5em 0em 0 0em rgba(128,128,128, 0.4), 1.75em 1.75em 0 0em rgba(128,128,128, 0.4), 0em 2.5em 0 0em rgba(128,128,128, 0.4), -1.8em 1.8em 0 0em rgba(128,128,128, 0.7), -2.6em 0em 0 0em rgba(128,128,128, 0.9), -1.8em -1.8em 0 0em #f5f5f5;
  }
}
@-webkit-keyframes load6 {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #f5f5f5, 1.8em -1.8em 0 0em rgba(128,128,128, 0.4), 2.5em 0em 0 0em rgba(128,128,128, 0.4), 1.75em 1.75em 0 0em rgba(128,128,128, 0.4), 0em 2.5em 0 0em rgba(128,128,128, 0.4), -1.8em 1.8em 0 0em rgba(128,128,128, 0.4), -2.6em 0em 0 0em rgba(128,128,128, 0.7), -1.8em -1.8em 0 0em rgba(128,128,128, 0.9);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(128,128,128, 0.9), 1.8em -1.8em 0 0em #f5f5f5, 2.5em 0em 0 0em rgba(128,128,128, 0.4), 1.75em 1.75em 0 0em rgba(128,128,128, 0.4), 0em 2.5em 0 0em rgba(128,128,128, 0.4), -1.8em 1.8em 0 0em rgba(128,128,128, 0.4), -2.6em 0em 0 0em rgba(128,128,128, 0.4), -1.8em -1.8em 0 0em rgba(128,128,128, 0.7);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(128,128,128, 0.7), 1.8em -1.8em 0 0em rgba(128,128,128, 0.9), 2.5em 0em 0 0em #f5f5f5, 1.75em 1.75em 0 0em rgba(128,128,128, 0.4), 0em 2.5em 0 0em rgba(128,128,128, 0.4), -1.8em 1.8em 0 0em rgba(128,128,128, 0.4), -2.6em 0em 0 0em rgba(128,128,128, 0.4), -1.8em -1.8em 0 0em rgba(128,128,128, 0.4);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(128,128,128, 0.4), 1.8em -1.8em 0 0em rgba(128,128,128, 0.7), 2.5em 0em 0 0em rgba(128,128,128, 0.9), 1.75em 1.75em 0 0em #f5f5f5, 0em 2.5em 0 0em rgba(128,128,128, 0.4), -1.8em 1.8em 0 0em rgba(128,128,128, 0.4), -2.6em 0em 0 0em rgba(128,128,128, 0.4), -1.8em -1.8em 0 0em rgba(128,128,128, 0.4);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(128,128,128, 0.4), 1.8em -1.8em 0 0em rgba(128,128,128, 0.4), 2.5em 0em 0 0em rgba(128,128,128, 0.7), 1.75em 1.75em 0 0em rgba(128,128,128, 0.9), 0em 2.5em 0 0em #f5f5f5, -1.8em 1.8em 0 0em rgba(128,128,128, 0.4), -2.6em 0em 0 0em rgba(128,128,128, 0.4), -1.8em -1.8em 0 0em rgba(128,128,128, 0.4);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(128,128,128, 0.4), 1.8em -1.8em 0 0em rgba(128,128,128, 0.4), 2.5em 0em 0 0em rgba(128,128,128, 0.4), 1.75em 1.75em 0 0em rgba(128,128,128, 0.7), 0em 2.5em 0 0em rgba(128,128,128, 0.9), -1.8em 1.8em 0 0em #f5f5f5, -2.6em 0em 0 0em rgba(128,128,128, 0.4), -1.8em -1.8em 0 0em rgba(128,128,128, 0.4);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(128,128,128, 0.4), 1.8em -1.8em 0 0em rgba(128,128,128, 0.4), 2.5em 0em 0 0em rgba(128,128,128, 0.4), 1.75em 1.75em 0 0em rgba(128,128,128, 0.4), 0em 2.5em 0 0em rgba(128,128,128, 0.7), -1.8em 1.8em 0 0em rgba(128,128,128, 0.9), -2.6em 0em 0 0em #f5f5f5, -1.8em -1.8em 0 0em rgba(128,128,128, 0.4);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(128,128,128, 0.4), 1.8em -1.8em 0 0em rgba(128,128,128, 0.4), 2.5em 0em 0 0em rgba(128,128,128, 0.4), 1.75em 1.75em 0 0em rgba(128,128,128, 0.4), 0em 2.5em 0 0em rgba(128,128,128, 0.4), -1.8em 1.8em 0 0em rgba(128,128,128, 0.7), -2.6em 0em 0 0em rgba(128,128,128, 0.9), -1.8em -1.8em 0 0em #f5f5f5;
  }
}
