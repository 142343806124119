
.container, .container-fluid, 
.row, [class^="row-"],
.col, [class^="col-"] {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  /* display: box; */
  display: flexbox;
  display: flex;
  
}

.container, .container-fluid {
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  width: 100%;
  height: 100%;

  
  -webkit-justify-content: space-between;
  justify-content: space-between;

  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -ms-flex-align: stretch;
  -ms-align-items: stretch;
  align-items: stretch;

  -webkit-align-content: stretch;
  align-content: stretch;
}


.row, [class^="row-"] {
  -ms-flex-direction: row !important;
  flex-direction: row !important;

  width: 100%;

  flex-grow: 1;

  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;

  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -ms-flex-align: stretch;
  -ms-align-items: stretch;
  align-items: stretch;

  -webkit-align-content: stretch;
  align-content: stretch;
}


.col.sc > .row { /* default?  use this to make rows' heights stretch to equally fill their parent container's height*/
  max-height: 100%;
  flex-grow: 1;
  /* color: red; */
}

.col.fc > .row { /*use this to make rows' heights shrink to match their content's height*/
  /* max-height: fit-content; */
  flex: 0 1 auto;
  /* color: red; */
}

.col.fc > .row.js-fg { /*use this to make a single row expand vertically to fill remaining space*/
  max-height: 100%;
  flex-grow: 1;
  /* color: red; */
}








.row-f-1 {
  flex: 1 auto;
}

.row-f-2 {
  flex: 2 auto;
}

.row-f-3 {
  flex: 3 auto;
}

.row-f-4 {
  flex: 4 auto;
}

.row-f-5 {
  flex: 5 auto;
}

.row-f-6 {
  flex: 6 auto;
}

.row-f-7 {
  flex: 7 auto;
}

.row-f-8 {
  flex: 8 auto;
}

.row-f-9 {
  flex: 9 auto;
}

.row-f-10 {
  flex: 10 auto;
}

.row-f-11 {
  flex: 11 auto;
}

.row-f-12 {
  flex: 12 auto;
}

.row-f-13 {
  flex: 13 auto;
}

.row-f-14 {
  flex: 14 auto;
}

.row-f-15 {
  flex: 15 auto;
}

.row-f-16 {
  flex: 16 auto;
}

.row-f-17 {
  flex: 17 auto;
}

.row-f-18 {
  flex: 18 auto;
}

.row-f-19 {
  flex: 19 auto;
}

.row-f-20 {
  flex: 20 auto;
}




/* .col-auto,    .col-1,    .col-2,    .col-3,    .col-4,    .col-5,    .col-6,    .col-7,    .col-8,    .col-9,     .col-10,   .col-11,    .col-12,
.col-sm, .col-sm-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12,
.col-md, .col-md-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12,
.col-lg, .col-lg-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12,
.col-xl, .col-xl-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12  */
.col, [class^="col-"] {
  -ms-flex-direction: column !important;
  flex-direction: column !important;

  flex: 1 auto;
  max-width: 100%;

  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;
  
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -ms-flex-align: stretch;
  -ms-align-items: stretch;
  align-items: stretch;

  -webkit-align-content: stretch;
  align-content: stretch;

}

/* .col > *:not(.row):not(.container-fluid):not(section):not(main):not(footer):not(header):not(button), 
[class^="col-"] > *:not(.row):not(.container-fluid):not(section):not(main):not(footer):not(header):not(button) {

} */


[class~="col"] > [class~="row"] {
  /* background: red !important */
  /* display: none; */
  /* max-height: fit-content !important; */
}



/* .col > span, .col > p, .col > b, .col > em, .col > h1, .col > h2, .col > h3, .col > h4, .col > h5, .col > h6, .col > a,
.col > img, .col > svg,
.col > input, .col > label, .col > select, .col > textarea, .col > hr 
.col > button,  */
:not(label) > .col > *:not(.row):not([class^="row-"]):not(.container-fluid):not(.container):not(.skip):not(p),
button > *,
.col > .row[style*="max-width"],
.row > *:not(.col):not([class^="col-"]):not(.container-fluid):not(.container) {
  justify-self: center;
  align-self: center;
  vertical-align: middle;
}

.col[style^="width"] > input {
  /* flex: 1 auto; */
  /* width: 100% !important;
  height: 100% !important; */
  color: red !important
}

.col > input {
  flex: 1 auto;
  width: 100%
}



.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}



.wrap {
  flex-wrap: wrap;
}

.no-wrap {
  flex-wrap: nowrap;
}

.jc-fs {
  justify-content: flex-start !important;
}

.jc-fe {
  justify-content: flex-end !important;
}

.jc-c {
  justify-content: center !important;
}

.jc-s {
  justify-content: stretch !important;
}

.jc-sb {
  justify-content: space-between !important;
}

.jc-sa {
  justify-content: space-around !important;
}

.jc-se {
  justify-content: space-evenly !important; 
}



.ac-fs {
  align-content: flex-start !important;
}

.ac-fe {
  align-content: flex-end !important;
}


.ac-c {
  align-content: center !important;
}

.ac-s {
  align-content: stretch !important;
}




.ai-fs {
  align-items: flex-start !important;
}

.ai-fe {
  align-items: flex-end !important;
}

.ai-c {
  align-items: center !important;
}

.ai-s {
  align-items: stretch !important;
}

.ai-sb {
  align-items: space-between !important;
}

.ai-b {
  align-items: baseline !important;
}

.ai-sa {
  align-items: space-around !important;
}

.ai-se {
  align-items: space-evenly !important; 
}

.ta-c {
  text-align: center !important;
  text-align-last: center !important;
  -moz-text-align-last: center !important;
  -ms-text-align-last: center !important;
}

.ta-l {
  text-align: left !important;
  text-align-last: left !important;
  -moz-text-align-last: left !important;
  -ms-text-align-last: left !important;
}

.ta-r {
  text-align: right !important;
  text-align-last: right !important;
  -moz-text-align-last: right !important;
  -ms-text-align-last: right !important;
}

.ta-j {
  text-align: justify !important;
  text-align-last: justify !important;
  -moz-text-align-last: justify !important;
  -ms-text-align-last: justify !important;
}



.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
