
.bg-white {
	background-color: #f5f5f5;
}

.bg-lightgrey {
  background-color:#c5c5c5;
}

.bg-grey {
  background-color: #626262;
}

.bg-darkgrey {
	background-color: #4c4c4c;
}

.bg-blackgrey {
	background-color: #151515;
}

.bg-greyblack {
	background-color: #202020;
}

.bg-black {
	background-color: #0a0a0a;
}



.text-white {
	color: #f5f5f5;
}

.text-lightgrey {
  color: #c5c5c5;
}

.text-grey {
  color: #737373;
}

.text-darkgrey {
	color: #4c4c4c;
}

.text-blackgrey {
	color: #151515;
}

.text-greyblack {
	color: #202020;
}

.text-black {
	color: #0a0a0a;
}


.border-white {
  border: 1px solid #f5f5f5;
}

.border-lightgrey {
  border: 1px solid #c5c5c5;
}

.border-grey {
  border: 1px solid #737373;
}

.border-darkgrey {
  border: 1px solid #4c4c4c;
}

.border-blackgrey {
  border: 1px solid #151515;
}

.border-greyblack {
  border: 1px solid #202020;
}

.border-black {
  border: 1px solid #0a0a0a;
}


.bg-grey-outline {
	border: 2px solid #737373;
  background: transparent;
  /* border-radius: "5px" */
}


.box-shadow {
  box-shadow: 0 1px 3px #737373;
}

.box-shadow-inset {
  box-shadow: 0 1px 3px #737373 inset;
}

.text-shadow {
  text-shadow: 0 1px 3px #737373;
}


/* .reset {
  border: 0;
  background: transparent;
  color: #626262;
} */

